import { useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import { Header } from "./components/header";
import { Pedigree } from "./components/pedigree";
import { SectionDivider } from "./components/sectionDivider";
import { Resource } from "./components/resource";
import { CONFIG } from "./CONFIG";
import { Footer } from "./components/footer";
import { Services } from "./components/services";

import logo1 from "./components/services/1.jpeg";
import logo2 from "./components/services/2.jpeg";
import logo3 from "./components/services/3.jpeg";

const theme = {
  palette: {
    basicallyBlack: " #161F33",
    darkestBlue: "#2B344A",
    blue: "#879FB2",
    offWhite: "#E6E9EF",
    white: "#FFFFFF",
    blueLink: "#87C0ED",
  },
};

const Container = styled.div(({ theme }) => ({
  position: "absolute",
  top: "89px",
  width: "100vw",
  height: "calc(100vh - 89px)",
  background: theme.palette.darkestBlue,
  overflowX: "hidden",
}));

const Box = styled.div(() => ({ height: 72 }));

const App = () => {
  const [showDivider, setShowDivider] = useState(false);
  const [servicesData, setServicesData] = useState([
    {
      title: "ADVISORY",
      preview: logo1,
      open: false,
      text: [
        "We are Veteran Interdisciplinary Advisors to the Global C-Suite",
        "We coined The Modern Transformation Paradigm©. Integrating Scientific Machine Learning, Scientific Human Services Design, and modern Corporate Strategy; our advisory practice stands alone in the world of AI today.",
        "Through rigorous scientific transformation, our world-class expertise helps organizations optimize operations for energy, production, yield, risk, and compliance. In a world where ~90% of AI transformations effectively fail within 18 months, our human-centered services platform guarantees adoption and lasting transformation.",
      ],
    },
    {
      title: "ENGINEERING",
      preview: logo2,
      open: false,
      text: [
       "We are Veteran Machine Learning Scientists Spanning Academia and Fortune 500",
        "Our Scientific Machine Learning engineers wrote vector machines and neural networks for industry, in the 1990s. From memory. By hand. Our real world AI experience predates the latest commercial AI trend by more than 30 years. We can explain AI.",
        "As professionals, we helped establish the field of Industrial Machine Learning itself. As researchers, we continue to produce field-defining research on time-series analytics, solving the toughest industrial problems left to be solved.", 
      ],
    },
    {
      title: "TRAINING",
      preview: logo3,
      open: false,
      text: [
        "We are Veteran Data Science Pedagogues for Industrials and Government Agencies",
        "In a constantly changing world there is no autonomous AI. No modern digital transformation will persist without formal - human-to-human - knowledge transfer.",
        "When quantifiable relevance, accuracy, and precision matter, formal and applied Data Science competencies outperform simple deployment of commercial AI software across all economic dimensions. Investing in formal Data Science competencies is the single most cost effective way to allocate capital in complex operating environments.",
      ],
    },
  ]);

  const openSection = (title) => {
    setServicesData((data) =>
      data.map((e) => ({
        ...e,
        open: e.title === title,
      }))
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Header
        showDivider={showDivider}
        onPedigree={() => {
          document.querySelector("#pedigree").scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }}
        onAdvisory={() => {
          openSection("ADVISORY");
          setTimeout(() => {
            document.querySelector("#advisory").scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }, 100);
        }}
        onEngineering={() => {
          openSection("ENGINEERING");
          setTimeout(() => {
            document.querySelector("#engineering").scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }, 100);
        }}
        onTraining={() => {
          openSection("TRAINING");
          setTimeout(() => {
            document.querySelector("#training").scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }, 100);
        }}
        onResources={() => {
          document.querySelector("#resources").scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }}
      />
      <Container
        setShowDivider={setShowDivider}
        onScroll={(e) => {
          if (e.target.scrollTop && !showDivider) {
            setShowDivider(true);
          } else if (!e.target.scrollTop && showDivider) {
            setShowDivider(false);
          }
        }}
      >
        <Pedigree />
        <SectionDivider>OUR SERVICES</SectionDivider>
        <Services servicesData={servicesData} openSection={openSection} />
        <div id="resources" />
        <SectionDivider>RESOURCES</SectionDivider>
        {CONFIG.OUR_SERVICES.map(({ TITLE, LINK }) => (
          <Resource key={TITLE} title={TITLE} link={LINK} />
        ))}
        <Box />
        <Footer />
      </Container>
    </ThemeProvider>
  );
};

export default App;
