import styled from "styled-components";
import { Typography } from "../typography";
import { DividerMark } from "../dividerMark";

const Container = styled.div(() => ({
  padding: "32px 40px 80px 40px",
}));

const Text = styled(Typography)(() => ({
  maxWidth: 475,
  paddingTop: 32,
}));

const TextBlock = styled.div(() => ({
  display: "flex",
  justifyContent: "center",
  gap: "10px",
  alignItems: "flex-start !important",
  flexWrap: "wrap",
}));

const Title = styled(Typography)(() => ({
  "@media only screen and (max-width: 963px)": {
    fontSize: "52px !important",
    lineHeight: "normal",
  },
  "@media only screen and (max-width: 1224px)": {
    fontSize: 78,
    lineHeight: "normal",
  },
}));

const MobileDivider = styled(DividerMark)(() => ({
  marginTop: 16,
  marginBottom: 16,
  "@media only screen and (min-width: 701px)": {
    display: "none",
  },
}));

const WebDivider = styled(DividerMark)(() => ({
  "@media only screen and (max-width: 700px)": {
    display: "none",
  },
}));

export const Pedigree = () => {
  return (
    <Container id="pedigree">
      <Title size="104" color="blue" line="137">
        Critical Thinking
      </Title>
      <MobileDivider />
      <Title size="104" color="blue" line="137">
        <WebDivider />
        For Critical Industries
      </Title>
      <TextBlock>
        <Text color="white" size="16" line="22">
          IQumulus is a global Data Science & Transformation Services firm
          headquartered in Austin, Texas. We help clients in asset and
          process intensive industries optimize operations with AI.
        </Text>
        <Text color="white" size="16" line="22">
         With 30 years of Scientific Machine Learning tradecraft, 
         honed in Heavy Industries, we stand far apart from the commercial competition.
        </Text>
      </TextBlock>
    </Container>
  );
};
